import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ExternalService } from 'angular4-hal';
import { JwtPayload, LoginRequest, ValidationSuccessResponse } from 'core/models';
import { AuthError } from 'core/models';
import * as _jwtDecode from "jwt-decode";
import { merge, partition, throwError, timer, Observable, Subscription,of } from 'rxjs';
import { filter, first, map, switchMap, tap,} from 'rxjs/operators';
import { LogoutSuccess, ValidationFailure, ValidationSuccess } from '../../actions';
import { authenticationReducer, IState } from '../../reducers';
import { getAuthenticationState } from '../../selectors';
import { IUserInfoProvider, USER_INFO_PROVIDER } from '../user-info-provider';
import {
  AUTH_LOGIN_URL,
  AUTH_LOGOUT_URL,
  AUTH_RENEW_URL,
  FORGOT_PASSWORD,
  IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY,
  IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE
} from './auth.constants';
import { AUTH_LOCAL_STORAGE_KEY } from '../../meta-reducers';

(window as any).global = window;

const jwtDecode = _jwtDecode;

const IGNORE_AUTHENTICATION_TOKEN_HEADERS = {
  headers: {
    [IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY]: IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE
  }
}

@Injectable()
export class AuthService {
  refreshSub: Subscription;
  private http: HttpClient;
  redirectToUrl: string;

  constructor(
    private store: Store<IState>,
    private router: Router,
    private external: ExternalService,
    @Inject(USER_INFO_PROVIDER)
    private userProvider: IUserInfoProvider
  ) {
    this.http = this.external.getHttp();
    this.listenForLocalStorageChange();
  }

  public handleAuthentication(request: LoginRequest = null): Observable<ValidationSuccessResponse> {
    const [savedUser$, noSavedUser$] = partition(this.store.select(getAuthenticationState)
      .pipe(
        first(),
        map(user => request ? null : user),
      ), (user) => user != null && user.encodedToken !== '');

    const existingToken$ = savedUser$;
    const newToken$ = this.doAuthorizationRequest(noSavedUser$, request);

    return merge(existingToken$, newToken$).pipe(
      switchMap((response: ValidationSuccessResponse) => {
        
        // Return the updated response including the new token
        return of({
          ...response, // Include the new token in the response
        });
      }),
      first(),
    );
  }

  private doAuthorizationRequest(newUser$: Observable<authenticationReducer.IAuthenticationState>, request: LoginRequest) {
    return newUser$.pipe(
      tap(() => {
        if (!request) {
          this.navigateToLogin(this.router.url);
        }
      }),
      filter(() => request != null),
      switchMap(() => this.userProvider.getUserInfoByEmail(request.username)),
      switchMap((userInfo: { userId: string, accountId: string, accountDomains: string }) => {
        return this.http.post(`${this.external.getExternalConfiguration()['authBaseUrl']}${AUTH_LOGIN_URL}`, {
          domain: userInfo.accountDomains.split('\n')[0],
          userName: request.username,
          password: request.password
        }, IGNORE_AUTHENTICATION_TOKEN_HEADERS);
      }),
      map((response: any) => this.parseJwt(response))
    );
  }

  private parseJwt(response: any): ValidationSuccessResponse {
    const token = response.DFMJwtToken;
    const payload = <JwtPayload>jwtDecode(token);
    if (this.external.getExternalConfiguration()['identityManagement'] == "WSO2") {
      return <ValidationSuccessResponse>{
        encodedToken: token,
        accessToken: payload.authData.access_token,
        email: payload.userData.email,
        expiresIn: payload.authData.expires_in,
        expiresAt: payload.exp,
        accountId: payload.accountInfo.accountId,
        userType: payload.userData.usertype,
        refreshToken: payload.authData.refresh_token,
        userId: payload.userData.userId,
        redirectToUrl: this.redirectToUrl,
        
      };
    } else {
      return <ValidationSuccessResponse>{
        encodedToken: token,
        email: payload.userData.email,
        expiresAt: payload.exp,
        accountId: payload.accountInfo.accountId,
        userType: payload.userData.usertype,
        userId: payload.userData.userId,
        redirectToUrl: this.redirectToUrl,
      }
    }
  }

  public logout(): void {
    this.unscheduleRenewal();
    this.removeAuthToken();
  }

  public removeAuthToken() {
    if (this.external.getExternalConfiguration()['identityManagement'] == "WSO2") {
      const url = `${this.external.getExternalConfiguration()['authBaseUrl']}${AUTH_LOGOUT_URL}`;

      this.http.delete(url).subscribe({
        next: () => {
          this.router.navigateByUrl('/login');
          this.store.dispatch(new LogoutSuccess());
        },
        error: () => {
          // this.router.navigateByUrl('/loggedOut');
        }
      });
    } else {
      this.router.navigateByUrl('/login');
      this.store.dispatch(new LogoutSuccess());
    }
  }

  public navigateToLogin(state: string): void {
    this.router.navigateByUrl('/login', { state: { state } });
  }

  public scheduleRenewal(expiresAt: number) {
    if (this.external.getExternalConfiguration()['identityManagement'] === "WSO2") {
      this.unscheduleRenewal();
      // const expiresIn$ = timer(Math.max(1, expiresAt * 1000 - Date.now()));
      const expiresIn$ = timer(Math.max(1, expiresAt * 1000 - (Date.now() + 30000)));

      this.refreshSub = expiresIn$.subscribe(() => {
        this.unscheduleRenewal();
        this.renewToken();
      });
    }
  }

  public renewToken(state = '/'): void {
    this.redirectToUrl = state
    const url = `${this.external.getExternalConfiguration()['authBaseUrl']}${AUTH_RENEW_URL}`;
    this.store.select(getAuthenticationState).pipe(
      first(),
      switchMap(user => {
        if (user && user.authorizationError) {
          return throwError(new Error(AuthError.AUTHORIZATION_ERROR));
        }
        if (!user || !user.encodedToken) {
          return throwError(new Error(AuthError.CANNOT_FIND_TOKEN));
        }

        return this.http.get(url, {
          headers: {
            'authToken': user.encodedToken,
            [IGNORE_AUTHENTICATION_TOKEN_HEADER_KEY]: IGNORE_AUTHENTICATION_TOKEN_HEADER_VALUE
          }
        });
      }),
      map((response: any) => this.parseJwt(response)),
      map((response: ValidationSuccessResponse) => ({ ...response, redirectToUrl: state })),
    ).subscribe({
      next: (user) => {
        this.store.dispatch(new ValidationSuccess(user));
      },
      error: (error) => {
        this.navigateToLogin(state);
        if (error.status === 401) {
          
          this.router.navigateByUrl('/sessionTimeout')
          this.store.dispatch(new ValidationFailure(error));
        } else
          if (error.message !== AuthError.AUTHORIZATION_ERROR) {
            this.store.dispatch(new ValidationFailure(error));
          }

      }
    });
  }

  public unscheduleRenewal() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

  public forgotPassword(email: string): Observable<Object> {
    return this.http
      .post(`${this.external.getExternalConfiguration()['authBaseUrl']}${FORGOT_PASSWORD}`, { email }, IGNORE_AUTHENTICATION_TOKEN_HEADERS)
  }

  listenForLocalStorageChange(): void {
    window.addEventListener('storage', (event) => {
      if (event.key == AUTH_LOCAL_STORAGE_KEY) {
        const newValue = JSON.parse(event.newValue);
        const oldValue = JSON.parse(event.oldValue || "{}");
        // DOC: User has logged-in or logged-out from different tab
        if ((newValue.encodedToken && (oldValue?.encodedToken != newValue.encodedToken)) ||
          (!newValue.encodedToken && oldValue?.encodedToken)) {
          window.location.reload();
        }
      }
    });
  }
}
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { AuthenticatedUser } from 'core/models';
import { rootReducers, rootSelectors } from 'core/root-store';

@Injectable({
  providedIn: 'root',
})
export class LoginGuardService implements CanActivate {
  routerState: RouterReducerState<rootReducers.routerReducer.IRouterStateUrl>;
  authenticatedUser: AuthenticatedUser;
  constructor(private store: Store<rootReducers.IState>, private router: Router) {
    this.store.pipe(select(rootSelectors.getRouterState)).subscribe((state) => {
      this.routerState = state;
    });
    this.store.pipe(select(rootSelectors.getAuthenticatedUser)).subscribe((user) => {
      this.authenticatedUser = user;
    });
  }

  canActivate(): boolean {
    if (this.authenticatedUser?.isAuthenticated) {
      this.router.navigateByUrl('/decision-first');
      return false;
    } else {
      return true;
    }
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, Actions, createEffect } from '@ngrx/effects';
import { BASE_ROUTE } from 'core/constants';
import { DBTableNames, IndexedDBService, MessageService } from 'core/services';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as authActions from '../../actions';
import { AuthService } from '../../services/auth/auth.service';
import { Store } from '@ngrx/store';
import { authenticationReducer, IState } from '../../reducers';
import { LogoutSuccess, ValidationFailure, ValidationSuccess } from '../../actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private router: Router,
    private messageService: MessageService,
    private indexedDbService: IndexedDBService,
    private store: Store<IState>,
  ) { }

  startLogin$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.START_LOGIN),
    tap((action: authActions.StartLogin) => {
      if (action.payload.state ? action.payload.state.url !== undefined : false) {
        this.auth.renewToken(action.payload.state.url);
      } else {
        this.auth.renewToken(BASE_ROUTE);
      }
    })
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.LOGOUT),
    tap(() => {
      this.auth.logout();
    })
  ), { dispatch: false });

  startValidation$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.START_VALIDATION),
    switchMap((action: authActions.StartValidation) =>
      this.auth.handleAuthentication(action.payload).pipe(
        map((authResult) => new authActions.ValidationSuccess(authResult)),
        catchError((error) => of(new authActions.ValidationFailure(error))),
      ),
    )
  ));

  validationFailure$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.VALIDATION_FAILURE),
    tap((action: authActions.ValidationFailure) => {
      console.log(action.payload);
    })
  ), { dispatch: false });

  authorizationFailure$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.AUTHORIZATION_FAILURE),
    tap((action: authActions.AuthorizationFailure) => {
      // console.log(action.payload);
    })
  ), { dispatch: false });

  validationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.VALIDATION_SUCCESS),
    tap((action: authActions.ValidationSuccess) => {
      this.auth.scheduleRenewal(action.payload.expiresAt);
      this.router.navigateByUrl(decodeURIComponent(action.payload.redirectToUrl !== undefined ? action.payload.redirectToUrl : ''));
      this.indexedDbService.getTableEntryCount(DBTableNames.TableSyncStatus).then(count => {
        if (count > 1) {
          this._clearDBTables();
        } else if (count == 1) {
          this.indexedDbService.getTableVersion(action.payload.accountId, DBTableNames.NameReference).then(result => {
            if (!result) this._clearDBTables();
          });
        }
      });
    })
  ), { dispatch: false });

  forgotPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.FORGOT_PASSWORD),
    switchMap((action: authActions.ForgotPassword) => {

      return this.auth.forgotPassword(action.payload).pipe(
        map(() => new authActions.ForgotPasswordSuccess()),
        catchError((error) => of(new authActions.ForgotPasswordFailure(error))),
      );
    })
  ));

  forgotPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.FORGOT_PASSWORD_SUCCESS),
    tap((action: authActions.ForgotPasswordSuccess) => {
      this.messageService.showSuccess(['resources.newPasswordHasBeenSuccessfullySentOnYourEmail'], 'resources.forgotPassword');
    })
  ), { dispatch: false });

  forgotPasswordFailure$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.FORGOT_PASSWORD_FAILURE),
    tap((action: authActions.ForgotPasswordFailure) => {
      this.messageService.handleError(action.payload, 'resources.forgotPassword');
    })
  ), { dispatch: false });

  private _clearDBTables(): void {
    this.indexedDbService.clearTableEntries(DBTableNames.TableSyncStatus);
    this.indexedDbService.clearTableEntries(DBTableNames.NameReference);
  }
}
